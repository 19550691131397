<template>

    <v-card elevation="0" class="w-100 ma-0 rounded-0 h-100 d-flex flex-column" color="rgb(255 255 255 / 80%)">
      
      <v-toolbar color="rgb(90 126 161)" flat dark>
        <v-toolbar-title>Образовательные организации</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchString"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
        <orgEdit v-if="$auth.user().isAdmin" :regions="regions" :organization="{}" @create="fetchData()" />
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-if="!loading" class="h-100">
        <v-list  color="transparent" class="h-100">
        <template v-for="item of list">
          <v-list-item :key="item.id" :to="'/edu-org/view/' + item.id">
            <v-list-item-actions>
              <v-chip class="mr-3">{{ item.id }}</v-chip>
            </v-list-item-actions>
            <v-list-item-content>
              {{ item.short_name }} 
              <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <orgEdit
            v-if="$checkRole(item.id,'admin')"
            :regions="regions"
              :organization="item"
              @update="
                $set(
                  list,
                  list.findIndex((el) => el.id === item.id),
                  $event
                )
              "
            />
            <div v-if="$auth.user().isAdmin">
            <v-btn
              icon
              @click.prevent.stop="archive(item)"
              :loading="item.archiving"
              v-if="item.status == 1"
            >
              <v-icon color="error">mdi mdi-package-down</v-icon>
            </v-btn>
            <v-btn
              icon
              :loading="item.archiving"
              @click.prevent.stop="archive(item)"
              v-if="item.status == 2"
            >
              <v-icon color="green">mdi mdi-package-up</v-icon>
            </v-btn>
            </div>
            </v-list-item-action>

          </v-list-item>
          <v-divider :key="'d' + item.id" />
        </template>
        <div v-if="list.length==0" class="w-100 h-100 d-flex align-center justify-center text-h6"><div>Ничего не найдено</div></div>
        <div
          v-intersect="fetchData"
        ></div>
      </v-list>

      </v-card-text>
      <div v-else class="w-100 h-100 d-flex align-center justify-center">
        <v-progress-circular 
    
      :size="60"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
      </div>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
const debounce = function (f, ms) {
  let timer = null;
  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(onComplete, ms);
  };
};
import orgEdit from "./edit.vue";
export default {
  components: {
    orgEdit,
  },
  data: () => ({
    pageName: {
      title: "Образовательные организации",
    },
    searchString: "",
    loading: true,
    fetching:false,
    page: 1,
    itemsPerPage: 10,
    headers: [
      { text: "ID", value: "id" },
      { text: "Название", value: "name" },
      { text: "Статус", value: "status" },
      { text: "Действия", value: "actions" },
    ],
    list: [],
    regions:[],
    count: 1,
  }),
  methods: {
    ...mapActions(['checkRole']),
    archive(item) {
      let index = this.list.findIndex((el) => el.id === item.id);
      this.$set(this.list[index], "archiving", true);
      this.$http
        .get("edu-org/archive", {
          params: {
            id: item.id,
          },
        })
        .then((response) => {
          item.status = response.data;
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.$set(this.list[index], "archiving", false);
        });
    },
    fetchData() {
      if(this.done || this.fetching){
        return
      }
      this.fetching=true
      this.$http
        .get(
          "edu-org/list?page=" +
            this.page +
            "&perPage=" +
            this.itemsPerPage +
            "&string=" +
            this.searchString
        )
        .then((response) => {


          this.list.push(...response.data.organizations);
          this.count = parseInt(response.data.count);
          this.page++
          if(response.data.organizations.length==0){
            this.done=true
          }
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false
          this.fetching=false
        });
    },
        loadRegions(){
      this.$http
          .get("edu-org/regions")
          .then((response) => {
            this.regions=response.data
          })
          .catch(() => {
            alert("Возникла ошибка");
          })
    },
    search: debounce(function () {
      this.page=1
       this.list=[]
       this.done=false
       this.loading = true
      this.fetchData();
    }, 500),
  },
  watch: {
    page() {
      this.fetchData();
    },
    itemsPerPage() {
      this.fetchData();
    },
    searchString() {
      this.search();
    },
  },
  mounted() {
    
    this.fetchData();
    this.loadRegions();
  },
};
</script>

<style>
</style>
